import React from "react";

function Footer(props) {
	return (
		<div className="vol-div">
			<h2>Vol. 1 © Justin Lombardi</h2>
		</div>
	);
}

export default Footer;
