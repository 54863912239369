import React from "react";

import HomeResults from "./HomeResults";

import "./Home.css";

function Home(props) {
	return (
		<div>
			<HomeResults />
		</div>
	);
}

export default Home;
